jQuery(document).ready(function($) {
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Molecule: Modal
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  
  function closeModal(el, trigger) {
    const $modal    = $(el).closest(".m-modal");
    const triggerId = $modal.data("controlledby");

    if ( $modal.hasClass('m-modal--required') ) return;

    $modal
      .removeClass("m-modal--open")
      .attr("tabindex", "-1");

    if (triggerId) {
      $(`#${triggerId}`)
        .removeClass('m-video-prompt--active')
        .focus();
    }

    $modal.trigger('close', [trigger]);
  }

  function openModal(id, focusOnModal, trigger) {
    const $modal = $(`#${id}`);

    $modal
      .addClass("m-modal--open")
      .attr("tabindex", "1");

    if (focusOnModal) {
      setTimeout(function() {
        $modal.focus();
      }, 100);
    } else {
      const $focusable = $modal.find(
        'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const $firstFocusable = $focusable[0];

      setTimeout(function() {
        $firstFocusable.focus();
      }, 100);
    }

    $modal.trigger('open', [trigger]);
  }

  function toggleModal(el, id, focusOnModal) {
    const $modal = $(`#${id}`);

    if ($modal.hasClass('m-modal--open')) {
      closeModal($modal.find('*:first'), el);
    } else {
      openModal(id, focusOnModal, el);
    }
  }

  $.fn.modal = function(){
    this.find('.m-modal__close, .m-modal__overlay').click(function() {
      closeModal(this);
    });

    this.find(".m-modal").keydown(function(e) {
      if (e.key === "Tab") {
        const $focusable = $(this).find(
          'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        const $firstFocusable = $focusable[0];
        const $lastFocusable  = $focusable[$focusable.length - 1];

        if (
          $(e.target).is($lastFocusable) && !e.shiftKey ||
          ($(e.target).is($firstFocusable) && e.shiftKey)
        ) {
          e.preventDefault();

          if ($(e.target).is($lastFocusable)) {
            $firstFocusable.focus();
          } else {
            $lastFocusable.focus();
          }
        } else if ($(e.target).is(".m-modal")) {
          e.preventDefault();

          $firstFocusable.focus();
        }
      } else if (e.key === "Escape" || e.key === "Esc") {
        if ( !$(e.target).is('.a-select *') ) {
          closeModal(this);
        }
      }
    });

    this.find('.m-modal').on('open', function(e) {
        const $modal = $(this);
        const $media = $modal.find('iframe');
        
        if ( $media[0] ) $modal.find('.m-video-player').trigger('playMedia');
    });

    this.find('.m-modal').on('close', function() {
        const $modal = $(this);
        const $media = $modal.find('video, audio, iframe');
        
        if ( $media[0] ) $modal.find('.m-video-player').trigger('stopMedia');
    });

    this.find('.m-video-player').on('playMedia', function(e) {

      if($(this).data('player').media.plyr){
        $(this).data('player').media.plyr.play();
      }else {
        $(this).data('player').media.play();
      }
    });

    this.find('.m-video-player').on('stopMedia', function(e) {
      if($(this).data('player').media.plyr){
        $(this).data('player').media.plyr.stop();
      }else{
        $(this).data('player').media.stop(); 
      }
    });
  };

  if($('.o-main')[0]){
    $(document).modal();
  }

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Molecule: Modal Trigger
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $.fn.modalTrigger = function() {
    this.find('.m-modal-trigger__trigger').mousedown(function() {
      const modalId     = $(this).attr("aria-controls");

      $(this).toggleClass('m-modal-trigger__trigger--active');
      toggleModal(this, modalId, true);
    });

    this.find('.m-modal-trigger__trigger').keydown(function(e) {
      if (e.key === 'Enter' || e.key === 'spacebar' || e.key === ' ') {
        e.preventDefault();

        const modalId     = $(this).attr("aria-controls");

        $(this).addClass('m-modal-trigger__trigger--active');
        openModal(modalId, false, this);
      }
    });
  };
  
  if($('.o-main')[0]){
    $('.o-main').modalTrigger();
  }
});
