jQuery(document).ready(function($) {

  
  if ( $('.m-notice')[0] ) {
    $('.m-notice').each(function() {
      const id = $(this).attr('id');

      if (localStorage.getItem(`hide_${id}`) !== '1') {
        $(this).show();
      } else {
        $(this).remove();
      }
    });

    $('.m-notice__close').click(function () {
      const $notice = $(this).closest('.m-notice');
      const id      = $notice.attr('id');

      $notice.remove();
      localStorage.setItem(`hide_${id}`, 1);
    });
  }

});