jQuery(document).ready(function($) {

  if($('.o-featured-recognitions__list--slider')[0]){
    $('.o-featured-recognitions__list--slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        accessibility: true,
        prevArrow: $('.o-featured-recognitions__arrow--prev'),
        nextArrow: $('.o-featured-recognitions__arrow--next'),

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });
  }
});