jQuery(document).ready(function($) {

  $(window).on("load resize", function () {
    const header = $('.o-header');
    const headerHeight = header.innerHeight();
    const main   = $('.o-main');
    let adminBarHeight = 0;
    
    if($('#wpadminbar')[0]){
      adminBarHeight = $('#wpadminbar').innerHeight();
      header.css('top' , adminBarHeight);
    }
    
    main.css('margin-top', headerHeight);
  });
  
});