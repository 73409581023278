jQuery(document).ready(function($) {

  if( $('.gfield_custom_radios')[0] ) {
    $(document).on("click", '.gfield_custom_radios .gfield_radio input[type=radio]', function() {
      $(this).closest('.gform_page').find('.gform_page_footer .gform_next_button.button').click();
    });
  }


  $(document).bind('gform_page_loaded', function(event, form_id, current_page){
    // code to be trigger when next/previous page is loaded
    if( $('.gfield_custom_radios')[0] ) {
      $('.gfield_custom_radios .gfield_radio input[type=radio]').bind("click", function() {
        $(this).closest('.gform_page_footer > input[type="button"]').click();
      });
    }
  });
});