jQuery(($) => {
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Browser - Keydown Focus Class
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $("body").on("keydown", function () {
    if (!$(".keyboard-in-use")[0]) {
      $(this).addClass("keyboard-in-use");
    }
  });

  $("body").on("mousedown", function () {
    if ($(".keyboard-in-use")[0]) {
      $(this).removeClass("keyboard-in-use");
    }
  });
});
