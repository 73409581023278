jQuery(document).ready(function($) {

  
  $.fn.videoPlayer = function() {
    
    this.find('.m-video-player').each(function(){
      const $video = $(this).find('.m-video-player__video');

      const player = new Plyr($video, {
        type      : 'video',
        controls  : ['play', 'current-time', 'progress', 'duration', 'mute', 'volume', 'pip', 'fullscreen'],
        autoplay  : false,
        loadSprite: false,
        fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
        youtube   : { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
      });

      $(this).data('player', player);

    });
  };

  if ($(".m-video-player")[0]) {
    $(document).videoPlayer();
  }
});