jQuery(document).ready(function($) {

  $(window).on('load resize', function() {
    if ($('.o-footer__notice')[0]) {
      const $notice = $('.o-footer__notice');
      const noticeHeight = $notice.innerHeight();

      $('.o-footer').css('padding-bottom', `${noticeHeight}px`);
    }
  });

  $('.o-footer__notice .m-notice__close').click(function() {
    $('.o-footer').removeAttr('style');
  });

});