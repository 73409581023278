jQuery(document).ready(function($) {

  $(window).on("scroll load", function () {
    const windowTop = $(this).scrollTop();
    const $header   = $('.o-header');
    
    if (windowTop > 50) {
      if ( !$('.o-header--scrolling')[0] ) {
        $header.addClass('o-header--scrolling');
      }
    } else {
      if ( $('.o-header--scrolling')[0] ) {
        $header.removeClass('o-header--scrolling');
      }
    }
  });


  $('.o-header__toggle').click(function() {
    $('.o-header').toggleClass('o-header--open');
    $('.o-header .m-page-nav__item:first-child a').focus();

    $('.o-header__search-bar:visible').trigger('unexpand');
  });

});