jQuery(document).ready(function($) {

  $(".m-nav-dropdown").mouseleave(function(e) {
    if ($(window).width() > 950) {
      $(this).trigger('unexpand');
    }
  });

  $(".m-nav-dropdown a").keydown(function(e) {
    const $dropdown = $(this).closest('.m-nav-dropdown');

    switch (e.key) {
      case "Tab":
        e.preventDefault();

        if (e.shiftKey) {
          var prevItem = e.target.parentElement.previousElementSibling;
          if (prevItem) $(prevItem).find('a').focus();
        } else {
          var nextItem = e.target.parentElement.nextElementSibling;
          if (nextItem) $(nextItem).find('a').focus();
        }
        break;

      case "ArrowUp":
        e.preventDefault();

        var prevItem = e.target.parentElement.previousElementSibling;
        if (prevItem) $(prevItem).find('a').focus();
        break;

      case "ArrowDown":
        e.preventDefault();

        var nextItem = e.target.parentElement.nextElementSibling;
        if (nextItem) $(nextItem).find('a').focus();
        break;

      case "Home":
      case "PageUp":
        e.preventDefault();

        $(this).closest('ul').find('li:first-child').find('a').focus();
        break;

      case "End":
      case "PageDown":
        e.preventDefault();

        $(this).closest('ul').find('li:last-child').find('a').focus();
        break;

      case "Escape":
      case "Esc":
        $dropdown.trigger('unexpand');
        break;
    }
  });


  $(".m-nav-dropdown__link[aria-haspopup]").keydown(function(e) {
    if (e.key === 'ArrowRight' || e.key === 'spacebar' || e.key === ' ') {
      e.preventDefault();

      const controlledId = $(this).attr('aria-controls');
      const $controlled  = $(`#${controlledId}`);

      $controlled.find('.m-nav-dropdown__subitem:first-child a').focus();
    }
  });


  $(".m-nav-dropdown__sublink").keydown(function(e) {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();

      const $panel       = $(this).closest('.m-nav-dropdown__panel');
      const controllerId = $panel.attr('data-controlledby');
      const $controller  = $(`#${controllerId}`);

      $controller.focus();
    }
  });


  $(".m-nav-dropdown a").blur(function (e) {
    if ($(window).width() > 950) {
      const newTarget = e.relatedTarget;

      if (
          newTarget == null
          || !newTarget.offsetParent.classList.contains("m-nav-dropdown")
      ) {
          $(this).closest('.m-nav-dropdown').trigger('unexpand');
      }
    }
  });


  $(".m-nav-dropdown__list .m-nav-dropdown__link").on('mouseenter focus', function() {
    const controlledId = $(this).attr('aria-controls');
    const $controlled  = $(`#${controlledId}`);

    $('.m-nav-dropdown__link').not($(this)).trigger('unexpandItem');
    
    if ($controlled[0]) {
      $('.m-nav-dropdown__panel').not($controlled).trigger('unexpandPanel');

      $(this).trigger('expandItem');
      $controlled.trigger('expandPanel');
    } else {
      $('.m-nav-dropdown__panel').trigger('unexpandPanel');
    }
  });
  

  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Expand
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $(".m-nav-dropdown").on('expand', function() {
    $(this).addClass('m-nav-dropdown--open');
  });

  $('.m-nav-dropdown__link').on('expandItem', function() {
    $(this).addClass('m-nav-dropdown__link--active');
    $(this).attr('aria-expanded', 'true');
  });

  $('.m-nav-dropdown__panel').on('expandPanel', function() {
    $(this).addClass('m-nav-dropdown__panel--active');
  });


  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Unexpand
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $(".m-nav-dropdown").on('unexpand', function() {
    const controllerId = $(this).attr('data-controlledby');
    const $controller  = $(`#${controllerId}`);

    $(this).removeClass('m-nav-dropdown--open');
    $controller.trigger('unexpand');
    $controller.next().trigger('unexpand');
    $controller.focus();

    $('.m-nav-dropdown__link').trigger('unexpandItem');
    $('.m-nav-dropdown__panel').trigger('unexpandPanel');
  });

  $('.m-nav-dropdown__link').on('unexpandItem', function() {
    $(this).removeClass('m-nav-dropdown__link--active');
    $(this).attr('aria-expanded', 'false');
  });

  $('.m-nav-dropdown__panel').on('unexpandPanel', function() {
    $(this).removeClass('m-nav-dropdown__panel--active');
  });

});