jQuery(document).ready(function($) {

  $(".m-page-nav__link").keydown(function(e) {
    if ($(window).width() <= 950) {
      if (e.key === "Tab") {
        const $focusable = $(this).closest('.m-page-nav__list').find(
          'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        );
  
        const $firstFocusable = $focusable[0];
        const $lastFocusable  = $focusable[$focusable.length - 1];
  
        if (
          $(e.target).is($lastFocusable) && !e.shiftKey ||
          ($(e.target).is($firstFocusable) && e.shiftKey)
        ) {
          e.preventDefault();
  
          $('.o-header__logo').focus();
        }
      } else if (e.key === "Escape" || e.key === "Esc") {
        $('.o-header__toggle').trigger('click');
      }
    }
  });


  $(".m-page-nav__link[aria-haspopup], .m-page-nav__dropdown-button").keydown(function(e) {
    const controlledId = $(this).attr('aria-controls');
    const $controlled  = $(`#${controlledId}`);

    if (e.key === "ArrowDown" || e.key === 'spacebar' || e.key === ' ') {
      e.preventDefault();
      $(this).closest('.m-page-nav__item').find('.m-page-nav__link[aria-haspopup], .m-page-nav__dropdown-button').trigger('expand');
      $controlled.trigger('expand');
      $controlled.find('.m-nav-dropdown__item:first-child .m-nav-dropdown__link').focus();
    } else if (e.key === "Escape" || e.key === "Esc") {
      $(this).closest('.m-page-nav__item').find('.m-page-nav__link[aria-haspopup], .m-page-nav__dropdown-button').trigger('unexpand');
      $controlled.trigger('unexpand');
    }
  });


  $(".m-page-nav__dropdown-button").click(function(e) {
    const controlledId = $(this).attr('aria-controls');
    const $controlled  = $(`#${controlledId}`);
    const isExpanded   = $(this).attr('aria-expanded') == 'true';
    
    if (!isExpanded) {
      $(this).trigger('expand');
      $(this).prev().trigger('expand');
      $controlled.trigger('expand');
      $controlled.find('.m-nav-dropdown__item:first-child .m-nav-dropdown__link').focus();
    } else {
      $(this).trigger('unexpand');
      $(this).prev().trigger('unexpand');
      $controlled.trigger('unexpand');
    }
  });


  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Expand
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $(".m-page-nav__link[aria-haspopup]").on('expand', function() {
    $(this).addClass('m-page-nav__link--active');
    $(this).attr('aria-expanded', 'true');
  });

  $(".m-page-nav__dropdown-button").on('expand', function() {
    $(this).addClass('a-dropdown-button--toggled');
    $(this).attr('aria-expanded', 'true');
  });


  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // ✅ Unexpand
  //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

  $(".m-page-nav__link[aria-haspopup]").on('unexpand', function() {
    $(this).removeClass('m-page-nav__link--active');
    $(this).attr('aria-expanded', 'false');
  });


  $(".m-page-nav__dropdown-button").on('unexpand', function() {
    $(this).removeClass('a-dropdown-button--toggled');
    $(this).attr('aria-expanded', 'false');
  });

});