jQuery(document).ready(function($) {

  if($('.o-hero-slider__slider')[0]){

    $('.o-hero-slider__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        arrows: true,
        dots: true,
        accessibility: true,
        adaptiveHeight: true,
        prevArrow: $('.o-hero-slider__previous'),
        nextArrow: $('.o-hero-slider__next'),
        appendDots: $('.o-hero-slider__dots')
    });
  }
});