jQuery(document).ready(function($){

  if($('.o-results__feed')[0]){
    let pendingLoad = 0;

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Page
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡    
    $.fn.page = function () {
      var page = parseInt($(this).hasClass('page-numbers.current')) || 1;

      if ($(this).hasClass('next page-numbers')) {
          page++;
      } else if ($(this).hasClass('prev page-numbers')) {
          page--;
      } else if ($(this).hasClass('page-numbers')) {
          page = parseInt($(this).text());
      }

      return page;
  };

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Get Results
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    function get_results($paged = 1) {
      const order        = $('.a-select__native').val();
      const $resultsFeed = $('.o-results__feed');
      const $loading     = $('.o-results__loading');
      const $pagination  = $('.o-results__pages');
      const query        = $('.o-results__results').data('search');

      $.ajax({
        type: "POST",
        url: AJAX.url,
        data: {
          action: 'get_results',
          nonce : AJAX.nonce,
          order : order,
          s     : query,
          paged : $paged,
        },
        dataType: "json",
        beforeSend: function() {
          pendingLoad++;
          $loading.addClass('a-loading-icon--loading');
        },

        success: function( results ){
          if(results.data.results){
            $resultsFeed.empty();
            $resultsFeed.append(results.data.results);
          }

          if(results.data.pagination){
            $pagination.empty();
            $pagination.append(results.data.pagination);
          }
        },
        complete: function() {
          pendingLoad--;
          $loading.removeClass('a-loading-icon--loading');
        }
      });
    }

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ SelectOrder
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    $('.a-select__item').mousedown(function (){
      get_results()
    });

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Pagination
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.o-results__pages').on('click', '.page-numbers', function(e){
      e.preventDefault();

      const $pages = $('.o-results__pages').find('.page-numbers');
      const $page  = $(this).page();


      $pages.removeClass('current');
      $(this).addClass('current');
      
      get_results($page);
    });
  }
});