jQuery(document).ready(function($) {

  if($('.js-loadmore-trigger')[0]){
    const $topic_items = $('.m-topics__item');
    const $loadMore    = $('.js-loadmore-trigger');

    $(window).on('load', function(){

      if($(window).width() > 768){
        if($topic_items && $topic_items.length > 12){
          $topic_items.slice(0, 11).show();
          $topic_items.slice(12).hide();
        }
      }
    });
  

    $('.o-large-posts-feed__all-topics').on('click', '.js-loadmore-trigger', function(e){
      e.preventDefault();
      
      if($('.m-topics__item:hidden').length <= 0){
        $topic_items.slice(12).slideUp();
        $loadMore.html('Load More');
      }
      
      if($('.m-topics__item:hidden').length >= 1){
        $('.m-topics__item:hidden').slice(0, 12).slideDown();
        
        if($('.m-topics__item:hidden').length <= 0){
          $loadMore.hide();
        }
      }
    });
  }

});