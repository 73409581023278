jQuery(($) => {

  // //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // // ✅ Molecule - Video
  // //≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
  // function playVideo(el) {
  //   const $video = $(el).closest(".m-video");
  //   const $iframe = $video.find(".m-video__iframe");
  //   const source = $iframe.data("src");

  //   $video.addClass("m-video--playing");
  //   $iframe.removeAttr("tabindex");
  //   $iframe.attr("src", `${source}&autoplay=true`);
  // }

  // function stopVideo(el) {
  //   const $video = $(el).closest(".m-video");
  //   const $iframe = $video.find(".m-video__iframe");
  //   const source = $iframe.data("src");

  //   $video.removeClass("m-video--playing");
  //   $iframe.attr("tabindex", "-1");
  //   $iframe.attr("src", "");
  // }

  // if ($(".m-video")[0]) {
  //   $(".m-video__trigger").click(function() {
  //     playVideo(this);
  //   });

  //   $(".m-video__trigger").keydown(function(e) {
  //     switch (e.key) {
  //       case "Enter":
  //       case "spacebar":
  //       case " ":
  //         playVideo(this);
  //         break;

  //       case "Escape":
  //       case "Esc":
  //         stopVideo(this);
  //         break;
  //     }
  //   });

  //   if($('.m-modal')[0]){
  //     $(".m-modal__close, .m-modal__overlay").click(function () {
  //       stopVideo($('.m-video__trigger'));
  //     });
  //   }
  // }
});