jQuery(document).ready(function($){

  if($('.o-large-posts-feed')[0]){
    let pendingLoad = 0;

//░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
// ∟Page
//░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░

    $.fn.page = function () {
      var page = parseInt($(this).hasClass('page-numbers.current')) || 1;

      if ($(this).hasClass('next page-numbers')) {
          page++;
      } else if ($(this).hasClass('prev page-numbers')) {
          page--;
      } else if ($(this).hasClass('page-numbers')) {
          page = parseInt($(this).text());
      }
      return page;
  };

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ FakeUrlParams
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    function fake_url_params($paged = '') {
      let query = '';
      const taxonomy_url    = $('#filters-category').data('taxonomy-url');
      const category_values = $('#filters-category').val();
      const tag_values     = $('#filters-tag').val().split(',');
      const tag_delimiter   = category_values ? '&' : '?';
      const paged_delimiter = category_values || tag_values[0] !== '' ? '&' : '?';
      const currentUrl      = taxonomy_url + window.location.search;

      if(category_values) query = '?category_name=' + category_values;
      if(tag_values[0] !== '') query += tag_delimiter + 'tag=' + tag_values.join('+');
      // if($paged) query += paged_delimiter + 'paged=' + $paged;
      // window.history.pushState(currentUrl, $filter.text() + " resources", query);
      return query;

    }

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ AddFiltersToHiddenInputs
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    function editFilterInputs(inputField, value) {
      const currentFilters = inputField.val().split(',');
      const newFilter      = value.toString();

      if (currentFilters.includes(newFilter)) {
        const i = currentFilters.indexOf(newFilter);
        currentFilters.splice(i, 1);
        inputField.val(currentFilters);
      } else {
        if(inputField.val() !== ''){
          inputField.val(inputField.val() + ',' + newFilter);
        }else{
          inputField.val(newFilter);
        }
      }
    }

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Get Resources
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    function get_resources($paged = '1', $order= null) {
      const $posts          = $('.o-large-posts-feed');
      const $hiddenTaxonomy = $posts.find('#filters-category');
      const $hiddenTags     = $posts.find('#filters-tag');
      const taxonomy_url    = $hiddenTaxonomy.data('taxonomy-url');
      const catIds          = $hiddenTaxonomy.val().split(',');
      const tagIds          = $hiddenTags.val().split(',');
      const $orderSelect    = $posts.find('.m-large-feed__order');
      // const order           = $posts.find('.a-select__native').val();
      const $featuredPosts  = $posts.find('.o-large-posts-feed__featured-posts');
      const $feedList       = $posts.find('.m-large-feed__feed');
      const $pages          = $posts.find('.o-large-posts-feed__pages');
      const $topicsList     = $posts.find('.o-large-posts-feed__all-topics');
      const $loading        = $posts.find('.o-large-posts-feed__loading');
      const $hero_title     = $('.o-blog-hero__heading');
      const $modals         = $('.o-footer__postModals');

      // console.log( order );
      $.ajax({
        type: "POST",
        url: AJAX.url,
        data: {
          action      : 'get_resources',
          nonce       : AJAX.nonce,
          activeFilter: catIds,
          activeTags  : tagIds,
          order       : $order,
          paged       : $paged,
        },
        dataType: "json",
        beforeSend: function() {
          pendingLoad++;
          $loading.addClass('a-loading-icon--loading');
        },
        success: function( results ){
          if( results.data){
          

            $feedList.empty();
            $feedList.append(results.data.items);

            $pages.empty();
            $pages.append(results.data.pagination);

            $topicsList.empty();
            $topicsList.append(results.data.topics);

            $featuredPosts.empty();
            $featuredPosts.append(results.data.featured);

            $modals.empty();
            $modals.append(results.data.modals).modal();

            $orderSelect.empty();
            $orderSelect.append(results.data.orderSelect).select();
            
            $(document).videoPlayer();
            $(document).modalTrigger();

            window.history.pushState(taxonomy_url, '', fake_url_params());

            catIds[0] !== 'all' ? $hero_title.hide() : $hero_title.show();

          }else{
            $feedList.empty();
            $feedList.append('<h4 class="m-large-feed__noresults u-h4"> NO RESULTS FOUND </h4>');
          }
        },
        complete: function() {
          pendingLoad--;
          $loading.removeClass('a-loading-icon--loading');
        }
      });
    }

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ CategoryFilters
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    $('.o-large-posts-feed__filter .a-filter-link[data-filter]').click(function() {
      if(!pendingLoad){
        const $filter        = $(this);
        const $filters       = $filter.closest('ul').find('button');
        const $filterParent  = $filter.closest('.m-filter-links');
        const catIds        = $('#filters-category');
        const tagIds        = $('#filters-tag');
    

        $filters.removeClass('a-filter-link--active');
        $filter.addClass('a-filter-link--pending');
        catIds.val('');
        tagIds.val('');

        editFilterInputs($('#filters-' + $(this).data('type')), $(this).data('filter'));

        get_resources();

        $filterParent.find('.m-filter-links__toggle').text($filter.text());
        $filter.removeClass('a-filter-link--pending').addClass('a-filter-link--active');

        if($(window).width() <= 768 ){
          setTimeout(function() {
            $filterParent.trigger('unexpand');
          });
        }
      }
    });

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ TopicFilters
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
    $('.o-large-posts-feed__all-topics').on('click', '.a-topic-link[data-topic]', function() {
      if(!pendingLoad){
        const $filter        = $(this);

        editFilterInputs($('#filters-' + $(this).data('type')), $(this).data('topic'));

        get_resources();
        fake_url_params($filter);

        $filter.toggleClass('.a-topic-link--active');
      }
    });

    $('.o-large-posts-feed__all-topics').on('click', '.m-topics__clear', function() {
      const $tagIds  = $('#filters-tag');
      const $filters = $(this).closest('.m-topics__list').find('button');
      const $filter  = $('.o-large-posts-feed__filter .a-filter-link--active');
  
      $tagIds.val('');
      $filters.removeClass('.a-topic-link--active');
  
      get_resources();
    });

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ SortByFilter
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.m-large-feed__order').on('mousedown', '.a-select__item', function() {
      const order  = $(this).data('value');
      let current = parseInt($('.page-numbers').closest('.m-pagination').find('.current').text());

      get_resources(current, order);
    });

//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Pagination
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡

    $('.o-large-posts-feed__pages').on('click', '.page-numbers', function(e){
      e.preventDefault();
      const order  = $('.o-large-posts-feed').find('.a-select__native').val()
      let current = parseInt($(this).closest('.m-pagination').find('.current').text());

      if($(this).hasClass('next')){
        current++;
      }else if($(this).hasClass('prev')){
        current--;
      }else{
        current = parseInt($(this).text());
      }
      
      get_resources(current, order);
    });

  }
});