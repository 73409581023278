jQuery(document).ready(function($) {

  // if ($('.m-filter-links__toggle')[0]) {
  //   $('.m-filter-links').on('expand', function() {
  //     $(this).find('.m-filter-links__toggle').addClass('m-filter-links__toggle--toggled');
  //     $(this).find('.m-filter-links__links').slideDown(200);
  //   });

  //   $('.m-filter-links').on('unexpand', function() {
  //     $(this).find('.m-filter-links__toggle').removeClass('m-filter-links__toggle--toggled');
  //     $(this).find('.m-filter-links__links').slideUp(200);
  //   });

  //   $('.m-filter-links__toggle').click(function () {
  //     const $filter = $(this).closest('.m-filter-links');

  //     if ($(this).is($('.m-filter-links__toggle--toggled'))) {
  //       $filter.trigger('unexpand');
  //     } else {
  //       $filter.trigger('expand');
  //     }
  //   });

  //   $(window).resize(function () {
  //     if ($('.m-filter-links__toggle--toggled')[0] || $('.m-filter-links__links[style]')[0]) {
  //       $('.m-filter-links').trigger('unexpand');
  //       $('.m-filter-links__links').removeAttr('style');
  //     }
  //   });
  // }

});